import type React from "react";

import type { IconName } from "@fortawesome/pro-regular-svg-icons";
import type { DefaultTheme, FlattenInterpolation, ThemeProps } from "styled-components";
import styled, { css } from "styled-components";

import Spinner from "../Spinner";

import { getIcon } from "./getIcon";

type Variant = "danger" | "primary" | "secondary" | "tertiary" | "tertiaryBlue";
export interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  loading?: boolean;
  dataTestID?: string;
  icon?: IconName;
  iconPlacement?: "start" | "end";
  text: string;
  small?: boolean;
  style?: Pick<React.CSSProperties, "color" | "margin" | "marginBottom" | "marginTop">;
  uppercase?: boolean;
  variant?: Variant;
}

export const BaseButton: React.VFC<BaseButtonProps> = ({
  active = false,
  loading,
  dataTestID,
  disabled,
  icon,
  iconPlacement = "start",
  text,
  type = "button",
  small = false,
  uppercase = false,
  variant = "primary",
  ...props
}) => {
  const iconComponent = getIcon(icon);

  return (
    <StyledButton
      active={active}
      data-testid={dataTestID}
      disabled={disabled}
      type={type}
      small={small}
      uppercase={uppercase}
      variant={variant}
      {...props}
    >
      {iconPlacement === "start" && iconComponent}
      {text}
      {iconPlacement === "end" && iconComponent}
      {loading && <Spinner style={{ height: "30px", width: "30px" }} />}
    </StyledButton>
  );
};

const baseStyles = css`
  border-radius: 32px;
  min-height: 50px;
  max-height: 50px;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 176px;
  padding: 0 40px;
`;

const styles: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  danger: css`
    ${baseStyles}
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.redesign.r100};
    color: ${({ theme }) => theme.colors.redesign.r100};
    font-weight: 600;

    &:active:enabled,
    &:hover {
      background: ${({ theme }) => theme.colors.redesign.r100};
      color: ${({ theme }) => theme.colors.white};
    }
  `,

  primary: css`
    ${baseStyles}
    background: ${({ theme }) => theme.colors.redesign.b100};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;

    &:active:enabled,
    &:hover {
      background: ${props => props.theme.colors.redesign.b70};
    }
    &:disabled {
      background: ${({ theme }) => theme.colors.redesign.db10};
      color: ${({ theme }) => theme.colors.redesign.db50};
    }
  `,

  secondary: css`
    ${baseStyles}
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.redesign.db30};
    color: ${({ theme }) => theme.colors.redesign.b100};
    flex-shrink: 1;

    &:active:enabled,
    &:hover {
      background: ${props => props.theme.colors.redesign.b70};
      color: ${props => props.theme.colors.white};
    }
  `,

  tertiary: css`
    background: "transparent";
    color: ${({ theme }) => theme.colors.primary.base};
    font-size: 16px;
    font-weight: 600;

    &:active:enabled,
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.redesign.db50};
    }
  `,

  tertiaryBlue: css`
    background: "transparent";
    color: ${({ theme }) => theme.colors.redesign.b100};
    font-size: 16px;
    font-weight: 600;

    &:active:enabled,
    &:hover {
      color: ${props => props.theme.colors.redesign.b70};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.redesign.db50};
    }
  `,
};

const StyledButton = styled.button<{ active: boolean; small: boolean; uppercase: boolean; variant: Variant }>`
  all: unset;
  outline: revert;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  white-space: nowrap;
  text-align: center;
  gap: 4px;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ variant }) => styles[variant]}
  ${({ active }) =>
    active &&
    css`
      background: ${props => props.theme.colors.redesign.b100};
      color: ${props => props.theme.colors.white};

      &:hover {
        background: ${props => props.theme.colors.redesign.b100};
        color: ${props => props.theme.colors.white};
      }
    `}
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ small }) =>
    small &&
    css`
      min-height: 32px;
      max-height: 32px;
      padding: 0 8px;
    `}
`;
