import { useMutation, useQueryClient } from "react-query";

import type { PutCareEventBillingArgs } from "api/schemas/CareEventBilling";
import useApiClient from "api/useApiClient";

import { getCareEventsBillingQueryKey } from "./useGetCareEventsBilling";

export const usePutCareEventBilling = () => {
  const queryClient = useQueryClient();
  const client = useApiClient();

  return useMutation<null, Error, PutCareEventBillingArgs>(args => client.putCareEventBilling(args), {
    onSuccess: () => {
      queryClient.invalidateQueries(getCareEventsBillingQueryKey());
    },
  });
};
