import type { AxiosRequestConfig } from "axios";
import { z } from "zod";

import { isStage } from "utils/misc/getBuildEnvironment";

import { AilmentSchema } from "./patients/HealthJournal";

export type GetPatientsArgsSchema = {
  userID: number;
  config?: AxiosRequestConfig<unknown> | undefined;
};

export const PatientsSchema = z.object({
  adherence: z.number().optional(),
  address_state: z
    .object({
      code: z.string(),
      name: z.string(),
    })
    .nullable()
    .optional(),
  ailment: AilmentSchema,
  appointment_to_schedule: z.string().nullable(),
  awaiting_treatment_ready: z.boolean(),
  billable_care_events: z.number().optional(),
  ce_structure: z.enum(["rtm", "invited_non_rtm", "hybrid", "digital"]),
  deleted: z.boolean(),
  email: z.string(),
  // Workaround for test patients not having a first_name
  first_name: isStage() ? z.string().nullable() : z.string(),
  id: z.number(),
  last_activity_completed_at: z.string().nullable().optional(),
  last_medical_referral_signature_at: z.string().nullable().optional(),
  // Workaround for test patients not having a last_name
  last_name: isStage() ? z.string().nullable() : z.string(),
  new_comments: z.boolean(),
  new_messages: z.boolean(),
  new_patient: z.boolean(),
  npo_consent: z.boolean().nullable(),
  payer_name: z.string(),
  payment_method: z.string(),
  // Workaround for test patients not having a preferred_name
  preferred_name: isStage() ? z.string().nullable() : z.string(),
  premium_activation_state: z.string().optional(),
  premium_type: z.enum(["none", "selfcare", "guided_care"]),
  primary_joint: z
    .object({
      location: z.string().nullable(),
      lateral_location: z.string().nullable(),
    })
    .nullable()
    .optional(),
  prio: z.boolean(),
  referral_process_status: z
    .enum(["questionnaire_submitted", "waiting_for_referral_upload", "rejected", "referral_uploaded"])
    .nullable(),
  state: z.enum([
    "discharged",
    "explore",
    "in_custom_course",
    "in_introduction",
    "in_onboarding",
    "in_selfcare",
    "in_six_week_course",
    "in_sustain_course",
    "new",
    "ready",
    "treatment_ended",
  ]),
  status: z.string(),
  subscription: z
    .object({
      status: z.string(),
      is_free_trial: z.boolean(),
    })
    .optional(),
  therapist_assignment_role: z.enum(["main_therapist", "substitute_therapist"]),
  us_insurance_profiles: z
    .array(
      z.object({
        name: z.string(),
        insurance_company_name: z.string(),
        status: z.string(),
        medicare: z.boolean(),
        immediate_referral_required: z.boolean().nullable(),
        pre_authorization_required: z.boolean().nullable(),
        insurance_company_supported: z.boolean().optional(),
      })
    )
    .optional(),
  us_provider_group_profile: z
    .object({
      claim_order_type: z.string(),
      immediate_referral_required: z.boolean().nullable(),
      insurance_company_name: z.string(),
      insurance_company_supported: z.boolean(),
      pre_authorization_required: z.boolean().nullable(),
      status: z.string(),
      id: z.number().nullable(),
    })
    .optional(),
  week: z.number(),
});

export type Patients = z.infer<typeof PatientsSchema>;
