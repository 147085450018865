import { enUS, svSE } from "@mui/x-data-grid/locales";

import { useProfileContext } from "contexts/ProfileContext";

export const useMUIDataGridLocale = () => {
  const { profile } = useProfileContext();
  const locales = { enUS, svSE };

  let language: keyof typeof locales;
  switch (profile?.locale) {
    case "en-GB":
    case "en-US":
      language = "enUS";
      break;
    case "sv-SE":
      language = "svSE";
      break;
    default:
      language = "enUS";
  }

  return locales[language];
};
