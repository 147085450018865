import i18n from "i18next";

import capitalize from "utils/string/capitalize";

interface Joint {
  location?: string;
  lateral_location?: string;
}

export interface PatientRow {
  ailment: string;
  primary_joint?: Joint;
}

export const getJointLabelValue = (row: PatientRow): string => {
  if (!row.primary_joint) return "";

  const { ailment, primary_joint } = row;
  const { lateral_location, location } = primary_joint;

  if (ailment === "fall_prevention") {
    return i18n.t("patients.ailments.fall_prevention");
  }

  if (ailment === "osteoporosis") {
    return i18n.t("patients.ailments.osteoporosis");
  }

  if (lateral_location === "both" && location !== "neck" && location !== "spine") {
    return capitalize(
      i18n.t("patients.pain_construct", {
        lateral: i18n.t(`patients.lateral_location.${lateral_location}`),
        location: i18n.t(`patients.pain_location.${location}.1`),
      })
    );
  }

  if (lateral_location === "undefined") {
    return i18n.t(`patients.pain_location.${location}.0`);
  }

  return capitalize(
    i18n.t("patients.pain_construct", {
      lateral: i18n.t(`patients.lateral_location.${lateral_location}`),
      location: i18n.t(`patients.pain_location.${location}.0`),
    })
  );
};
