import { useCallback, useEffect, useMemo } from "react";

import {
  type GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import type { Patients } from "api/schemas/Patients";
import { useProfileContext } from "contexts/ProfileContext";
import { MUIDataGrid } from "shared/molecules/MUIDataGrid";
import PatientStatus from "shared/molecules/PatientStatus";
import { DataRegionTypes } from "types";
import { isCypressRunning } from "utils/misc/isCypressRunning";
import { getAppDataRegion } from "utils/region";

import DateLabel from "../DateLabel";
import { formatCoverage } from "../queries/fetchPatients";

import { Icons } from "./components";
import { getJointLabelValue } from "./getJointLabelValue";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer style={{ justifyContent: "space-between" }}>
      <GridToolbarColumnsButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

const paginationModel = { page: 0, pageSize: 50 };

interface Props {
  isLoading: boolean;
  patients: Patients[];
}

export const PatientsTable: React.VFC<Props> = ({ isLoading, patients }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const apiRef = useGridApiRef();
  const region = getAppDataRegion();
  const { profile } = useProfileContext();
  invariant(profile);

  const resizeColumns = useCallback(() => {
    if (!apiRef.current || isCypressRunning()) return;

    requestAnimationFrame(() => {
      apiRef.current.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
      });
    });
  }, []);

  const columns = useMemo((): GridColDef[] => {
    let columnsArray: GridColDef[] = [
      {
        field: "id",
        headerName: t("patients.id"),
      },
      {
        field: "name",
        headerName: t("patients.name"),
        valueGetter: (_value, row) => `${row.first_name || ""} ${row.last_name || ""}`,
      },
      {
        field: "icons",
        headerName: t("patients.icons"),
        renderCell: params => <Icons params={params} />,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "ailment",
        headerName: t("patients.ailment"),
        valueGetter: (_value, row) => getJointLabelValue(row),
      },
      {
        field: "week",
        headerName: t("patients.week"),
      },
      {
        field: "status",
        headerName: t("patients.status"),
        renderCell: ({ row }) => <PatientStatus patientStatuses={row.status} />,
      },
      {
        field: "last_activity_completed_at",
        headerName: t("patients.last_activity_completed_at"),
        renderCell: ({ row }) => <DateLabel date={row.last_activity_completed_at} />,
      },
      {
        field: "adherence",
        headerName: t("patients.adherence"),
        renderCell: ({ row }) => <DisplayAdherence adherence={row.adherence}>{row.adherence}%</DisplayAdherence>,
      },
    ];

    if (region === DataRegionTypes.US || region === DataRegionTypes.UK) {
      columnsArray.push({
        field: "coverage",
        renderHeader: () => {
          switch (getAppDataRegion()) {
            case DataRegionTypes.US:
              return t("patients.header_info.coverage");
            case DataRegionTypes.UK:
              return t("form.payer_name");
            default:
              return "";
          }
        },
        valueGetter: (_value, row) => formatCoverage(t, row),
      });
    }

    if (region === DataRegionTypes.US) {
      columnsArray = columnsArray.concat([
        {
          field: "subscription",
          headerName: t("patients.header_info.subscription"),
          valueGetter: (_value, row) =>
            `${row.subscription?.status ?? ""} ${
              row.subscription?.is_free_trial ? `(${t("patients.header_info.subscription_free_trial")})` : ""
            }`,
        },
        {
          field: "address_state",
          headerName: t("patients.header_info.address_state"),
          valueGetter: (_value, row) => row.address_state?.code,
        },
      ]);
    }

    return columnsArray;
  }, []);

  useEffect(() => {
    resizeColumns();
  }, [patients]);

  return (
    <Container>
      <MUIDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={patients}
        onCellClick={({ field, row }) => {
          if (field !== "_icons") {
            navigate(`/patients/${row.id}`, { state: { pathname, text: "patients" } });
          }
        }}
        loading={isLoading}
        initialState={{ pagination: { paginationModel } }}
        slots={{ toolbar: CustomToolbar }}
        onPaginationModelChange={resizeColumns}
        rowSelection={false}
      />
    </Container>
  );
};

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

const DisplayNumber = styled.div`
  margin: auto;
  color: ${props => props.theme.colors.greys.dark};
  font-weight: ${props => props.theme.fontWeight.regular};
`;

const DisplayAdherence = styled(DisplayNumber)<{ adherence: number }>`
  color: ${props => props.adherence < 60 && props.theme.colors.orange};
`;
