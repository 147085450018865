import type { AxiosInstance, AxiosResponse } from "axios";
import queryString from "query-string";
import type z from "zod";

import { reportError } from "utils/errorReporting";
import { isStage } from "utils/misc/getBuildEnvironment";

import type { DeleteAppointmentArgs } from "./hooks/useDeleteAppointment";
import type { DeleteAppointmentManagerArgs } from "./hooks/useDeleteAppointmentManager";
import type { DeleteQuickReplyArgs } from "./hooks/useDeleteQuickReply";
import type { DeleteServiceArgs } from "./hooks/useDeleteService";
import type { GetAppointmentsArgs } from "./hooks/useGetAppointments";
import type { PostAppointmentNoShow } from "./hooks/usePostAppointmentNoShow";
import type { PostQuickReplyArgs } from "./hooks/usePostQuickReply";
import type { PutAppointmentCompleted } from "./hooks/usePutAppointmentCompleted";
import type { PutAppointmentPayment } from "./hooks/usePutAppointmentPayment";
import type { CareEvent } from "./models/CareEvent";
import { ActivityLibraryEntriesSchema } from "./schemas/ActivityLibraryEntry";
import { AppointmentSchema } from "./schemas/Appointment";
import { type AvailableAilmentsArgs, AvailableAilmentsSchema } from "./schemas/AvailableAilments";
import type {
  DeleteBookingIntervalArgs,
  PostManagerBookingIntervalArgs,
  PutBookingIntervalArgs,
} from "./schemas/BookingInterval";
import { type PostBookingIntervalArgs } from "./schemas/BookingInterval";
import type { PostCareEventSuggestedArgs, PutCareEventBillingArgs } from "./schemas/CareEventBilling";
import { CareEventBillingSchema } from "./schemas/CareEventBilling";
import type { EarningsUsTherapistArgs } from "./schemas/EarningsUsTherapist";
import { HCProfessionalResponseSchema } from "./schemas/HCProfessional";
import type { HcpService } from "./schemas/HcpService";
import { HcpServiceManagerSchema, HcpServiceSchema, ServiceTemplateSchema } from "./schemas/HcpService";
import type {
  GetManagerPatientsResponse,
  GetPatientAvailableJointsArgs,
  PostIntroductionHealthReportArgs,
  PostPatientOnboardingCompleteArgs,
  PutPatientOnboardingArgs,
} from "./schemas/ManagerBooking";
import {
  GetManagerPatientsResponseSchema,
  IntroductionHealthReportResponseSchema,
  PatientAvailableJointsResponseSchema,
  PatientOnboardingResponseSchema,
  PatientResponseSchema,
  type PostPatientArgs,
} from "./schemas/ManagerBooking";
import { AppointmentsResponseSchema, NewAppointmentSchema } from "./schemas/NewAppointment";
import { RawPartnerPatientSchema } from "./schemas/PartnerPatientsSchema";
import { PartnerUnclaimedReferralSchema } from "./schemas/PartnerUnclaimedReferralSchema";
import type { GetPatientsArgsSchema } from "./schemas/Patients";
import { PatientsSchema } from "./schemas/Patients";
import { type FeelingReportArgs, FeelingReportSchema } from "./schemas/patients/FeelingReport";
import { type HealthJournal, type HealthJournalArgs, HealthJournalSchema } from "./schemas/patients/HealthJournal";
import { type SwitchLateralLocationArgs, SwitchLateralLocationSchema } from "./schemas/patients/SwitchLateralLocation";
import {
  type SwitchTreatmentProgramsArgs,
  SwitchTreatmentProgramsSchema,
} from "./schemas/patients/SwitchTreatmentPrograms";
import { type TreatmentAdherenceArgs, TreatmentAdherenceSchema } from "./schemas/patients/TreatmentAdherence";
import { QuickReplySchema } from "./schemas/QuickReply";
import type { GetSmartRepliesArgs } from "./schemas/SmartReply";
import { CommentRepliesSchema, MessageRepliesSchema } from "./schemas/SmartReply";
import {
  CareEventSuggestedSchema,
  PostTreatmentActivityLogResponseSchema,
  TreatmentActivityLogCategoriesSchema,
  TreatmentActivityLogSchema,
  TreatmentActivityLogsPatientSchema,
} from "./schemas/TreatmentActivityLog";
import type { PostTreatmentActivityLogArgs, PutTreatmentActivityLogArgs } from "./schemas/TreatmentActivityLog";
import type {
  CreateUserCourseTemplatePayload,
  PutUserCoursePayload,
  UpdateUserCourseTemplatePayload,
} from "./schemas/UserCourse";
import {
  GetUserCourseLibaryResponseSchema,
  GetUserCourseResponseSchema,
  GetUserCourseTemplateResponseSchema,
  GetUserCourseTemplatesResponseSchema,
  UserCourseLibraryLessonsSchema,
} from "./schemas/UserCourse";
import type { GetActivityLibraryEntriesArgs } from "./types/GetActivityLibraryEntriesArgs";
import type { GetActivityLibraryEntryResponse } from "./types/GetActivityLibraryEntry";
import type { GetAuthenticatedUserResponse } from "./types/GetAuthenticatedUser";
import type { GetCurrentCourseArgs, GetCurrentCourseResponse } from "./types/GetCurrentCourse";
import type { GetHealthReportsArgs, GetHealthReportsResponse } from "./types/GetHealthReportsResponse";
import type { GetMedicalReferralsResponse } from "./types/GetMedicalReferrals";
import type { GetPainGraphArgs, GetPainGraphResponse } from "./types/GetPainGraph";
import type { GetPatientArgs, GetPatientResponse } from "./types/GetPatient";
import type { GetPatientCareEventsArgs, GetPatientCareEventsResponse } from "./types/GetPatientCareEvents";
import type {
  GetPatientCurrentSubscriptionArgs,
  GetPatientCurrentSubscriptionResponse,
} from "./types/GetPatientCurrentSubscription";
import type { GetPatientGoalsArgs, GetPatientGoalsResponse } from "./types/GetPatientGoals";
import type { GetPatientIcdCodesArgs, GetPatientIcdCodesResponse } from "./types/GetPatientIcdCodes";
import type { GetPatientNoteArgs, GetPatientNoteResponse } from "./types/GetPatientNote";
import type { GetPatientUnreadCommentsCountResponse } from "./types/GetPatientUnreadCommentsCount";
import type {
  GetPatientUsProviderGroupProfileArgs,
  GetPatientUsProviderGroupProfileResponse,
} from "./types/GetPatientUsProviderGroupProfile";
import type {
  GetPatientWidgetsPainLocationArgs,
  GetPatientWidgetsPainLocationResponse,
} from "./types/GetPatientWidgetsPainLocation";
import type { GetPhysicalFunctionGraphArgs, GetPhysicalFunctionGraphResponse } from "./types/GetPhysicalFunctionGraph";
import type { GetPlansOfCareArgs, GetPlansOfCareResponse } from "./types/GetPlansOfCare";
import type { GetPTCareEventsArgs, GetPTCareEventsResponse } from "./types/GetPTCareEvents";
import type { GetSuggestedCareEventsArgs, GetSuggestedCareEventsResponse } from "./types/GetSuggestedCareEvents";
import type { GetTherapistEarningsArgs, GetTherapistEarningsResponse } from "./types/GetTherapistEarnings";
import type { GetUsPatientInsuranceArgs, GetUsPatientInsuranceResponse } from "./types/GetUsPatientInsurance";
import type {
  PostAppointmentArgs,
  PostAppointmentManagerArgs,
  PutAppointmentArgs,
  PutAppointmentManagerArgs,
} from "./types/PostAppointment";
import type {
  PostDismissAppointmentSuggestionArgs,
  PostDismissAppointmentSuggestionResponse,
} from "./types/PostDismissAppointmentSuggestion";
import type { PostMedicalNoteAddendumArgs } from "./types/PostMedicalNoteAddendum";
import type { CreateReferralVariables, PostMedicalReferralResponse } from "./types/PostMedicalReferral";
import type { PostServiceArgs, PutServiceArgs } from "./types/PostService";
import type { PutPatientCareEventArgs, PutPatientCareEventResponse } from "./types/PutPatientCareEvent";
import type { PutPatientMarkAsPrioArgs, PutPatientMarkAsPrioResponse } from "./types/PutPatientMarkAsPrio";
import type { PutPlanOfCareArgs, PutPlanOfCareResponse } from "./types/PutPlanOfCare";
import type ReferralProcessStatus from "./types/PutReferralProcessStatus";
import type { PutTherapistArgs, PutTherapistResponse } from "./types/PutTherapist";

type Schema = z.ZodType<unknown>;

/**
 * Validate the data response against the associated zod schema.
 *
 * @param response Response to validate
 * @param schema The schema to check agains
 * @param throwErrorWhenFailing If validating fails an error should be thrown
 *
 * @returns the validated data OR the unvalidated data only type casted
 */
const validateResponse = <T extends Schema>(
  response: AxiosResponse,
  schema: T,
  throwErrorWhenFailing = false
): z.infer<T> => {
  const { data, config } = response;
  const result = schema.safeParse(data);
  if (result.success) {
    return result.data as z.infer<T>;
  }

  const { error } = result;
  const cleanedUrl = config?.url?.replace(/\d+/g, "{id}")?.replace(/\?.*$/, "");
  reportError(`DataValidation error in ${cleanedUrl}`, error);

  // New endpoints will usually want to throw errors instead of returning faulty data
  if (throwErrorWhenFailing) throw error;

  /**
   * If we end up here the data is corrupt or our Zod schemas are faulty.
   * The desired behaviour is to throw an error when that happens.
   *
   * We decided to slowly transition into this data validation without crashing the app at this stage.
   * That is why we also return the "unvalidated" data. I.e. same behaviour as without any validation.
   */
  return data as z.infer<T>;
};

export class ApiClient {
  private transport: AxiosInstance;

  private transportV2: AxiosInstance;

  private transportLeifGPT: AxiosInstance;

  constructor(transport: AxiosInstance, transportV2: AxiosInstance, transportLeifGPT: AxiosInstance) {
    this.transport = transport;
    this.transportV2 = transportV2; // To use v2, this.transportV2 instead of this.transport
    this.transportLeifGPT = transportLeifGPT; // To use v2, this.transportV2 instead of this.transport

    this.putReferralProcessStatus = this.putReferralProcessStatus.bind(this);
    this.postUploadDoctorReferral = this.postUploadDoctorReferral.bind(this);
  }

  async getAppointments(userId: number) {
    const params = { filter: "since_today" };
    const response = await this.transport.get(`/therapists/${userId}/appointments`, { params });
    return validateResponse(response, AppointmentSchema.array(), true);
  }

  async getPartnerUnclaimedReferrals() {
    const response = await this.transport.get(`/unclaimed_medical_referrals`);
    return validateResponse(response, PartnerUnclaimedReferralSchema.array(), true);
  }

  async getPartnerPatientList(queryParam: string) {
    const response = await this.transport.get(`/clinic_partners/patients${queryParam}`);
    return validateResponse(response, RawPartnerPatientSchema, true);
  }

  async getActivityLibraryEntries(locale: string, query: GetActivityLibraryEntriesArgs) {
    const request = await this.transport.get(`/activity_library/entries`, { params: { locale, ...query } });
    return validateResponse(request, ActivityLibraryEntriesSchema, true);
  }

  async putReferralProcessStatus({ patientId, status }: { patientId: string; status: ReferralProcessStatus }) {
    const respond = await this.transport.put(`/patients/${patientId}/referral_process_status`, {
      referral_process_status: status,
    });
    return respond.data as void;
  }

  async postUploadDoctorReferral({ file, patientId }: { file: File; patientId: string }) {
    const data = new FormData();
    data.append("file", file, file.name);
    data.append("type", "application/pdf");
    data.append("reason", "none");

    const response = await this.transport.post(`/patients/${patientId}/medical_referrals`, data);
    if (response.data.id) {
      return true;
    }
    return false;
  }

  getActivityLibraryEntry(id: string, locale: string): Promise<GetActivityLibraryEntryResponse> {
    return this.transport.get(`/activity_library/entries/${id}`, { params: { locale } }).then(({ data }) => data);
  }

  getAuthenticatedUser(): Promise<GetAuthenticatedUserResponse> {
    if (isStage()) return this.transportV2.get<GetAuthenticatedUserResponse>("/me").then(({ data }) => data);

    return this.transport.get<GetAuthenticatedUserResponse>("/me").then(({ data }) => data);
  }

  getHealthReports(args: GetHealthReportsArgs): Promise<GetHealthReportsResponse> {
    const { patientId, treatment_id } = args;
    const params = { patient_id: patientId, includes: "answers", treatment_id };

    return this.transport.get(`/health_reports`, { params }).then(({ data }) => data);
  }

  getMedicalReferrals(patientId: number): Promise<GetMedicalReferralsResponse> {
    return this.transport.get(`/patients/${patientId}/medical_referrals`).then(({ data }) => data);
  }

  getPainGraphData(args: GetPainGraphArgs): Promise<GetPainGraphResponse> {
    const { patientId, treatment_id } = args;
    const params = { treatment_id };

    return this.transport.get(`patients/${patientId}/widgets/reports`, { params }).then(({ data }) => data);
  }

  async getPatients(args: GetPatientsArgsSchema) {
    const { userID, config } = args;

    const response = await this.transport.get(`therapists/${userID}/patients`, config);
    return validateResponse(response, PatientsSchema.array(), false);
  }

  getPatient(args: GetPatientArgs): Promise<GetPatientResponse> {
    const { patientId, treatment_id } = args;
    const params = { treatment_id };

    return this.transport.get(`patients/${patientId}`, { params }).then(({ data }) => data);
  }

  getPatientCareEvents(args: GetPatientCareEventsArgs): Promise<GetPatientCareEventsResponse> {
    const { signed, patientId } = args;
    const params = { filter: signed ? "signed" : "unsigned" };

    return this.transport.get(`patients/${patientId}/care_events`, { params }).then(({ data }) => data);
  }

  getPatientCurrentCourse(args: GetCurrentCourseArgs): Promise<GetCurrentCourseResponse> {
    const { patientId, treatment_id } = args;
    const params = { treatment_id };

    return this.transport.get(`/patients/${patientId}/current_course`, { params }).then(({ data }) => data);
  }

  getPatientCurrentSubscription(
    args: GetPatientCurrentSubscriptionArgs
  ): Promise<GetPatientCurrentSubscriptionResponse> {
    const { patientId } = args;

    return this.transport.get(`patients/${patientId}/subscriptions/current`).then(({ data }) => data);
  }

  getPatientGoals(args: GetPatientGoalsArgs): Promise<GetPatientGoalsResponse> {
    const { patientId, currentPeriod = false, treatment_id } = args;
    const params = { period: currentPeriod ? "current" : undefined, ...(treatment_id && { treatment_id }) };

    return this.transport.get(`patients/${patientId}/goals`, { params }).then(({ data }) => data);
  }

  async getPatientHealthJournal(args: HealthJournalArgs): Promise<HealthJournal> {
    const { patientId, treatment_id } = args;
    const params = { treatment_id };

    const response = await this.transport.get(`patients/${patientId}/health_journal`, { params });
    return validateResponse(response, HealthJournalSchema, false);
  }

  getPatientIcdCodes(args: GetPatientIcdCodesArgs): Promise<GetPatientIcdCodesResponse> {
    const { patientId } = args;

    return this.transport.get(`patients/${patientId}/icd_codes`).then(({ data }) => data);
  }

  getPatientNote(args: GetPatientNoteArgs): Promise<GetPatientNoteResponse> {
    const { patientId, therapistId } = args;

    return this.transport.get(`/patients/${patientId}/note?therapist_id=${therapistId}`).then(({ data }) => data);
  }

  getPatientUnreadCommentsCount(patientId: number): Promise<GetPatientUnreadCommentsCountResponse> {
    return this.transport.get(`/patients/${patientId}/unread_comments_count`).then(({ data }) => data);
  }

  getPatientUsProviderGroupProfile(
    args: GetPatientUsProviderGroupProfileArgs
  ): Promise<GetPatientUsProviderGroupProfileResponse> {
    const { patientId } = args;

    return this.transport.get(`/patients/${patientId}/us_provider_group_profile`).then(({ data }) => data);
  }

  getPatientWidgetsPainLocation(
    args: GetPatientWidgetsPainLocationArgs
  ): Promise<GetPatientWidgetsPainLocationResponse> {
    const { patientId, treatment_id } = args;
    const params = { treatment_id };

    return this.transport.get(`patients/${patientId}/widgets/pain_locations`, { params }).then(({ data }) => data);
  }

  getPhysicalFunctionGraphData(args: GetPhysicalFunctionGraphArgs): Promise<GetPhysicalFunctionGraphResponse> {
    const { patientId, treatment_id } = args;
    const params = { treatment_id };

    return this.transport
      .get(`patients/${patientId}/widgets/functionality_history`, { params })
      .then(({ data }) => data);
  }

  async getPatientFeelingReports(args: FeelingReportArgs) {
    const { patientID, treatment_id } = args;
    const params = { treatment_id };
    const response = await this.transport.get(`/patients/${patientID}/widgets/feeling_reports`, { params });
    return validateResponse(response, FeelingReportSchema.array(), false);
  }

  getTherapistEarnings(args: GetTherapistEarningsArgs): Promise<GetTherapistEarningsResponse> {
    const { date, therapistId } = args;

    return this.transport
      .get(`dashboard/earnings_therapist?month=${date}&therapist_id=${therapistId}`)
      .then(({ data }) => data);
  }

  getPlansOfCare(args: GetPlansOfCareArgs): Promise<GetPlansOfCareResponse> {
    const { acknowledgedByPT, patientId } = args;

    return this.transport
      .get(`patients/${patientId}/us_patient_plans_of_care?acknowledged_by_therapist=${acknowledgedByPT}`)
      .then(({ data }) => data);
  }

  getPTCareEvents(args: GetPTCareEventsArgs): Promise<GetPTCareEventsResponse> {
    const { userId } = args;

    return this.transport.get(`therapists/${userId}/care_events?filter=unsigned`).then(({ data }) => data);
  }

  getSuggestedCareEvents(args: GetSuggestedCareEventsArgs): Promise<GetSuggestedCareEventsResponse> {
    const { therapistId, patientId } = args;

    return this.transport
      .get(`/therapists/${therapistId}/suggest_care_events?patient_id=${patientId}`)
      .then(({ data }) => data);
  }

  getUsPatientInsurance(args: GetUsPatientInsuranceArgs): Promise<GetUsPatientInsuranceResponse> {
    const { patientId } = args;

    return this.transport.get(`us_insurance/patient_insurance/${patientId}`).then(({ data }) => data);
  }

  postMedicalReferral({
    patientId,
    reason,
    duration,
    icdCodes,
    doctorId,
  }: CreateReferralVariables): Promise<PostMedicalReferralResponse> {
    const formattedIcdCodes = icdCodes.map(item => item.value);
    return this.transport
      .post(`/patients/${patientId}/medical_referrals`, {
        reason,
        duration_days: duration,
        icd_codes: formattedIcdCodes,
        signed: true,
        doctor_id: doctorId,
      })
      .then(({ data }) => data);
  }

  putPatientCareEvent(args: PutPatientCareEventArgs): Promise<PutPatientCareEventResponse> {
    return this.transport.put(`care_events/${args.careEventId}`, args.data);
  }

  putPatientMarkAsPrio(args: PutPatientMarkAsPrioArgs): Promise<PutPatientMarkAsPrioResponse> {
    const { data: body, patientId } = args;

    return this.transport
      .put<PutPatientMarkAsPrioResponse>(`/patients/${patientId}/mark_as_prio`, body)
      .then(({ data }) => data);
  }

  putPlanOfCare(args: PutPlanOfCareArgs): Promise<PutPlanOfCareResponse> {
    return this.transport.put(`/us_patient_plans_of_care/${args.careEventId}`, args.data);
  }

  putTherapist(args: PutTherapistArgs): Promise<PutTherapistResponse> {
    const { data: body, therapistId } = args;

    return this.transport.put<PutTherapistResponse>(`/therapists/${therapistId}`, body).then(({ data }) => data);
  }

  // BOOKING v2
  async getNewAppointments(args: GetAppointmentsArgs) {
    const { userId, startDate, view } = args;
    const response = await this.transportV2.get(
      `/health_care_professionals/${userId}/appointments?start_date=${startDate}&view=${view}`
    );
    return validateResponse(response, AppointmentsResponseSchema, false);
  }

  async getPatientAppointments(userId: number, patientId: number) {
    const response = await this.transportV2.get(
      `/health_care_professionals/${userId}/patients/${patientId}/appointments`
    );
    return validateResponse(response, NewAppointmentSchema.array(), false);
  }

  async getHcpServices(userId: number): Promise<HcpService[]> {
    const response = await this.transportV2.get(`/health_care_professionals/${userId}/services`);
    return validateResponse(response, HcpServiceSchema.array(), false);
  }

  async postAppointment(args: PostAppointmentArgs) {
    const { data: body, hcProfId } = args;
    const response = await this.transportV2.post(`/health_care_professionals/${hcProfId}/appointments`, body);
    return validateResponse(response, NewAppointmentSchema, false);
  }

  postAppointmentNoShow(args: PostAppointmentNoShow) {
    const { appointmentID, hcProfID } = args;
    return this.transportV2
      .post(`/health_care_professionals/${hcProfID}/appointments/${appointmentID}/no-show`)
      .then(({ data }) => data);
  }

  async putAppointment(args: PutAppointmentArgs) {
    const { data: body, hcProfId, appointmentId } = args;
    return this.transportV2
      .put(`/health_care_professionals/${hcProfId}/appointments/${appointmentId}/reschedule`, body)
      .then(({ data }) => data);
  }

  async putAppointmentCompleted(args: PutAppointmentCompleted) {
    const { appointmentID } = args;
    const response = await this.transport.put(`/appointments/${appointmentID}`, { state: "completed" });
    return validateResponse(response, AppointmentSchema, false);
  }

  deleteAppointment(args: DeleteAppointmentArgs) {
    const { hcProfId, appointmentId } = args;
    return this.transportV2
      .delete(`/health_care_professionals/${hcProfId}/appointments/${appointmentId}`)
      .then(({ data }) => data);
  }

  async postBookingInterval(args: PostBookingIntervalArgs) {
    const { data: body, hcProfID } = args;
    return this.transportV2
      .post(`/health_care_professionals/${hcProfID}/booking_intervals`, body)
      .then(({ data }) => data);
  }

  putBookingInterval(args: PutBookingIntervalArgs) {
    const { data: body, hcProfID, bookingIntervalID } = args;
    return this.transportV2
      .put(`/health_care_professionals/${hcProfID}/booking_intervals/${bookingIntervalID}`, body)
      .then(({ data }) => data);
  }

  deleteBookingInterval(args: DeleteBookingIntervalArgs) {
    const { hcProfID, bookingIntervalID } = args;
    return this.transportV2
      .delete(`/health_care_professionals/${hcProfID}/booking_intervals/${bookingIntervalID}`)
      .then(({ data }) => data);
  }

  async postManagerBookingInterval(args: PostManagerBookingIntervalArgs) {
    const { data: body, managerID, hcProfID } = args;
    return this.transportV2
      .post(`/managers/${managerID}/health_care_professionals/${hcProfID}/booking_intervals`, body)
      .then(({ data }) => data);
  }

  // BOOKING v2 - Services
  async getServiceTemplate(managerId: number) {
    const response = await this.transportV2.get(`/managers/${managerId}/service_template`);
    return validateResponse(response, ServiceTemplateSchema, false);
  }

  async getManagerServices(managerId: number) {
    const response = await this.transportV2.get(`/managers/${managerId}/services`);
    return validateResponse(response, HcpServiceManagerSchema.array(), false);
  }

  async postService(args: PostServiceArgs) {
    const { data: body, managerId } = args;
    const response = await this.transportV2.post(`/managers/${managerId}/services`, body);
    return validateResponse(response, HcpServiceManagerSchema, false);
  }

  async putService(args: PutServiceArgs) {
    const { data: body, managerId } = args;
    const response = await this.transportV2.put(`/managers/${managerId}/services`, body);
    return validateResponse(response, HcpServiceManagerSchema, false);
  }

  async deleteService(args: DeleteServiceArgs) {
    const { managerId, serviceId } = args;
    return this.transportV2.delete(`/managers/${managerId}/services/${serviceId}`).then(({ data }) => data);
  }

  // BOOKING v2 - Endpoints used when manager books an appointment for a patient over the phone
  async postPatient(args: PostPatientArgs) {
    const { data: body, managerId } = args;
    const response = await this.transport.post(`/managers/${managerId}/patient`, body);
    return validateResponse(response, PatientResponseSchema, false);
  }

  async getPatientAvailablePainfulJoints(args: GetPatientAvailableJointsArgs) {
    const { managerId, patientId } = args;
    const response = await this.transport.get(`/managers/${managerId}/patients/${patientId}/available_painful_joints`);
    return validateResponse(response, PatientAvailableJointsResponseSchema, false);
  }

  async postPatientIntroductionHealthReport(args: PostIntroductionHealthReportArgs) {
    const { data: body, managerId, patientId } = args;
    const response = await this.transport.post(
      `/managers/${managerId}/patients/${patientId}/patient_onboarding/introduction_health_report`,
      body
    );
    return validateResponse(response, IntroductionHealthReportResponseSchema, false);
  }

  async getPatientAvailableAilments(args: AvailableAilmentsArgs) {
    const { therapistID, patientID } = args;
    const response = await this.transport.get(`/therapists/${therapistID}/patients/${patientID}/available_ailments`);
    return validateResponse(response, AvailableAilmentsSchema, false);
  }

  async putPatientSwitchLateralLocation(args: SwitchLateralLocationArgs) {
    const { patientID, data: body } = args;
    const response = await this.transport.put(`/patients/${patientID}/lateral_location`, body);
    return validateResponse(response, SwitchLateralLocationSchema, false);
  }

  async postPatientSwitchTreatmentPrograms(args: SwitchTreatmentProgramsArgs) {
    const { data: body, therapistID, patientID } = args;
    const response = await this.transport.post(
      `/therapists/${therapistID}/patients/${patientID}/switch_treatment`,
      body
    );
    return validateResponse(response, SwitchTreatmentProgramsSchema, false);
  }

  async putPatientOnboarding(args: PutPatientOnboardingArgs) {
    const { data: body, managerId, patientId } = args;
    const response = await this.transport.put(`/managers/${managerId}/patients/${patientId}/patient_onboarding`, body);
    return validateResponse(response, PatientOnboardingResponseSchema, false);
  }

  postPatientOnboardingComplete(args: PostPatientOnboardingCompleteArgs) {
    const { managerId, patientId } = args;
    return this.transport
      .post(`/managers/${managerId}/patients/${patientId}/patient_onboarding/complete`)
      .then(({ data }) => data);
  }

  async getHCProfessionals(managerID: number) {
    const response = await this.transportV2.get(`/managers/${managerID}/professionals`);
    return validateResponse(response, HCProfessionalResponseSchema.array(), false);
  }

  async getManagerPatients(managerId: number): Promise<GetManagerPatientsResponse[]> {
    const response = await this.transportV2.get(`managers/${managerId}/patients`);
    return validateResponse(response, GetManagerPatientsResponseSchema.array(), false);
  }

  async getAppointmentsManager(args: GetAppointmentsArgs) {
    const { userId, startDate, view } = args;
    const response = await this.transportV2.get(
      `/managers/${userId}/appointments?start_date=${startDate}&view=${view}`
    );
    return validateResponse(response, AppointmentsResponseSchema, false);
  }

  async postAppointmentManager(args: PostAppointmentManagerArgs) {
    const { data: body, managerId } = args;
    const response = await this.transportV2.post(`/managers/${managerId}/appointments`, body);
    return validateResponse(response, NewAppointmentSchema, false);
  }

  async putAppointmentManager(args: PutAppointmentManagerArgs) {
    const { data: body, managerId, appointmentId } = args;
    const response = await this.transportV2.put(
      `/managers/${managerId}/appointments/${appointmentId}/reschedule`,
      body
    );
    return validateResponse(response, NewAppointmentSchema, false);
  }

  deleteAppointmentManager(args: DeleteAppointmentManagerArgs) {
    const { managerId, appointmentId } = args;
    return this.transportV2.delete(`/managers/${managerId}/appointments/${appointmentId}`).then(({ data }) => data);
  }

  async putAppointmentPayment(args: PutAppointmentPayment) {
    const { data: body, appointmentId, managerId } = args;
    return this.transportV2
      .put(`/managers/${managerId}/appointments/${appointmentId}/payment`, body)
      .then(({ data }) => data);
  }

  postDismissAppointmentSuggestion(
    args: PostDismissAppointmentSuggestionArgs
  ): Promise<PostDismissAppointmentSuggestionResponse> {
    const { data: body, patientId } = args;

    return this.transport.post(`/patients/${patientId}/dismiss_appointment_suggestion`, body).then(({ data }) => data);
  }

  postPatientEndTreatment(patientId: number) {
    return this.transport.post(`/patients/${patientId}/end_treatment`).then(({ data }) => data);
  }

  async getQuickReplies(userId: number) {
    const response = await this.transport.get(`/users/${userId}/quick_replies`);
    return validateResponse(response, QuickReplySchema.array(), true);
  }

  async postQuickReply({ userId, text }: PostQuickReplyArgs) {
    const response = await this.transport.post(`/users/${userId}/quick_replies`, { text });
    return validateResponse(response, QuickReplySchema, true);
  }

  async deleteQuickReply({ userId, id }: DeleteQuickReplyArgs) {
    const response = await this.transport.delete(`/users/${userId}/quick_replies/${id}`);
    return validateResponse(response, QuickReplySchema, true);
  }

  postMedicalNoteAddendum(args: PostMedicalNoteAddendumArgs): Promise<CareEvent> {
    const { body, careEventId } = args;

    return this.transport.post(`/care_events/${careEventId}/addendum`, { body }).then(({ data }) => data);
  }

  postCableConnectionUrls(): Promise<{ url: string }> {
    return this.transport.post(`/cable/connection_urls`).then(({ data }) => data);
  }

  getPeronalInvitationLink(therapistId: number) {
    return this.transport.put(`/therapists/${therapistId}/public_profile`).then(({ data }) => data);
  }

  async postMarkTreatmentReady(patientId: number) {
    return this.transport.post(`/patients/${patientId}/mark_treatment_ready`).then(({ data }) => data);
  }

  // v2
  async getUserCourseLibraryExercises() {
    const response = await this.transportV2.get(`/activity_library/exercises`);
    return validateResponse(response, GetUserCourseLibaryResponseSchema, true);
  }

  async getUserCourseLibraryLessons() {
    const response = await this.transportV2.get(`/activity_library/lessons`);
    return validateResponse(response, UserCourseLibraryLessonsSchema, true);
  }

  async getUserCourse(patientId: number) {
    const response = await this.transportV2.get(`/patients/${patientId}/current_course`);
    return validateResponse(response, GetUserCourseResponseSchema, false);
  }

  async getUserCourseTemplate(templateId: number) {
    const response = await this.transportV2.get(`/treatment_templates/${templateId}`);
    return validateResponse(response, GetUserCourseTemplateResponseSchema, false);
  }

  async putUserCourse(patientId: number, course: PutUserCoursePayload) {
    return this.transportV2.put(`/patients/${patientId}/current_course`, course).then(({ data }) => data);
  }

  async getUserCourseTemplates() {
    const response = await this.transportV2.get(`/treatment_templates`);
    return validateResponse(response, GetUserCourseTemplatesResponseSchema, false);
  }

  async createUserCourseTemplate(therapistId: number, course: CreateUserCourseTemplatePayload) {
    return this.transportV2.post(`/therapists/${therapistId}/treatment_templates`, course).then(({ data }) => data);
  }

  async updateUserCourseTemplate(therapistId: number, templateId: number, course: UpdateUserCourseTemplatePayload) {
    return this.transportV2
      .put(`/therapists/${therapistId}/treatment_templates/${templateId}`, course)
      .then(({ data }) => data);
  }

  async deleteUserCourseTemplate(therapistId: number, templateId: number) {
    return this.transportV2
      .delete(`/therapists/${therapistId}/treatment_templates/${templateId}`)
      .then(({ data }) => data);
  }

  // LeifGPT
  async getSmartCommentReplies(args: GetSmartRepliesArgs) {
    const { therapistId, patientId } = args;
    const response = await this.transportLeifGPT.get(`/exercise_comment_replies/${therapistId}/${patientId}`);
    return validateResponse(response, CommentRepliesSchema, false);
  }

  async getSmartMessageReplies(args: GetSmartRepliesArgs) {
    const { therapistId, patientId } = args;
    const response = await this.transportLeifGPT.get(`/message_replies/${therapistId}/${patientId}`);
    return validateResponse(response, MessageRepliesSchema, false);
  }

  // Treatment Activity Log
  async getTreatmentActivityLogs() {
    const response = await this.transportV2.get("/therapist/treatment_activity_logs/pending");
    return validateResponse(response, TreatmentActivityLogSchema.array(), false);
  }

  async getTreatmentActivityLogCategories() {
    const response = await this.transportV2.get("/therapist/treatment_activity_log/categories");
    return validateResponse(response, TreatmentActivityLogCategoriesSchema, false);
  }

  async getTreatmentActivityLogsPatient(patientID: number) {
    const response = await this.transportV2.get(`/therapist/treatment_activity_logs/patients/${patientID}`);
    return validateResponse(response, TreatmentActivityLogsPatientSchema, false);
  }

  async postTreatmentActivityLog(args: PostTreatmentActivityLogArgs) {
    const { data: body } = args;
    const response = await this.transportV2.post("/therapist/treatment_activity_log", body);
    return validateResponse(response, PostTreatmentActivityLogResponseSchema, false);
  }

  async putTreatmentActivityLog(args: PutTreatmentActivityLogArgs) {
    const { id, data: body } = args;
    const response = await this.transportV2.put(`/therapist/treatment_activity_log/${id}`, body);
    return validateResponse(response, PostTreatmentActivityLogResponseSchema, false);
  }

  async getCareEventsSuggested(patientIDs: number[]) {
    const response = await this.transportV2.get("/therapist/care_events/suggested", {
      params: { patient_id: patientIDs },
      paramsSerializer: params => {
        return queryString.stringify(params);
      },
    });
    return validateResponse(response, CareEventSuggestedSchema.array(), false);
  }

  async postCareEventSuggested(args: PostCareEventSuggestedArgs) {
    const { data: body, patientID } = args;
    return this.transport.post(`/patients/${patientID}/care_events/suggested`, body).then(({ data }) => data);
  }

  async getCareEventsBilling() {
    const response = await this.transportV2.get("/managers/billing/care_events");
    return validateResponse(response, CareEventBillingSchema.array(), false);
  }

  async putCareEventBilling(args: PutCareEventBillingArgs) {
    const { data: body } = args;
    return this.transportV2.put("/managers/billing/care_events/update", body).then(({ data }) => data);
  }

  async getCareEvent(careEventID: number) {
    return this.transport.get(`/care_events/${careEventID}`).then(({ data }) => data);
  }

  async getEarningsUsTherapist(args: EarningsUsTherapistArgs) {
    const { therapist_id, start_date } = args;

    return this.transport
      .get("/dashboard/earnings_us_therapist", {
        params: {
          therapist_id,
          start_date,
        },
      })
      .then(({ data }) => data);
  }

  async getEnrollmentBonuses(args: EarningsUsTherapistArgs) {
    const { therapist_id, start_date } = args;

    return this.transport
      .get("/dashboard/enrollment_bonuses", {
        params: {
          therapist_id,
          start_date,
        },
      })
      .then(({ data }) => data);
  }

  async getTreatmentAdherence(args: TreatmentAdherenceArgs) {
    const { patientID, treatmentID } = args;

    const response = await this.transportV2.get(`/therapist/patients/${patientID}/treatments/${treatmentID}/adherence`);
    return validateResponse(response, TreatmentAdherenceSchema, false);
  }
}

export default ApiClient;
