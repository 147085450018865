import type { UserRole } from "api/models/User";
import { isTypeUserRole } from "api/models/User";
import type { Market } from "types";
import type { UserProfile } from "utils/profile/profileHelper";

export const Feature = {
  BACK_LIBRARY: "BACK_LIBRARY",
  CALL_PATIENT: "CALL_PATIENT",
  DISCHARGE_NOTE_RESOLUTION: "DISCHARGE_NOTE_RESOLUTION",
  EDIT_NOTE_DATE: "EDIT_NOTE_DATE",
  END_TREATMENT: "END_TREATMENT",
  MARK_PATIENT_AS_PRIO: "MARK_PATIENT_AS_PRIO",
  PATIENT_MESSAGES: "PATIENT_MESSAGES",
  PATIENT_STICKY_NOTE: "PATIENT_STICKY_NOTE",
  R52_ICD_CODE: "R52_ICD_CODE",
  REFERRALS: "REFERRALS",
  USE_ICD_CODES_API: "USE_ICD_CODES_API",
  WRITE_PATIENT_NOTE: "WRITE_PATIENT_NOTE",
  PATIENT_PROTOCOL: "PATIENT_PROTOCOL",
  PATIENT_COMMENTS: "PATIENT_COMMENTS",
  PATIENT_SUGGESTED_CARE_EVENT: "PATIENT_SUGGESTED_CARE_EVENT",
  PATIENT_SIGN_NOTES_TABLE: "PATIENT_SIGN_NOTES_TABLE",
  PATIENT_SCHEDULE_CALL_BOX: "PATIENT_SCHEDULE_CALL_BOX",
  SELFCARE: "SELFCARE",
  SHOW_AWAITING_TREATMENT_READY: "SHOW_AWAITING_TREATMENT_READY",
} as const;
export type Feature = typeof Feature[keyof typeof Feature];

export type CriteriaConditionsProfile = {
  roles?: UserRole[];
};

export const isTypeCriteriaConditionsProfile = (value: unknown): value is CriteriaConditionsProfile => {
  if (value && typeof value === "object" && value !== null) {
    if (Object.keys(value).length === 0) {
      return true;
    }
    const toTest = value as CriteriaConditionsProfile;
    if (toTest.roles && Array.isArray(toTest.roles)) {
      const tested = toTest.roles.filter(role => isTypeUserRole(role));
      return tested.length === toTest.roles.length;
    }
  }

  return false;
};

/**
 * Condition to be validated
 * If all conditions are true the feature can be considered active
 */
export type CriteriaConditions = {
  market?: Market[];
  profile?: CriteriaConditionsProfile;
};

export type CriteriaConditionNames = keyof CriteriaConditions;
export type ToggleOptions = {
  profile?: UserProfile;
};

type CriteriaConditionValidator = (condition: unknown, options?: ToggleOptions) => boolean;
export type CriteriaConditionValidators = Record<CriteriaConditionNames, CriteriaConditionValidator>;

export type CheckFunction<T extends string> = (featurePath: T) => boolean;

/**
 * Feature setting/setup
 * This allows to define if a specific feature is to be active or not and what are the criteria to consider it active
 * When active if false the feature will not be available (otherwise it should validate the criteria)
 *
 * It is expected to have one or more criteria conditions. The idea is to have the feature active if one of the criteria is true (OR)
 * Criteria1 OR Criteria2 OR Criteria3 ...
 *
 * While each individual criteriaConditions needs to fulfill all its conditions (AND).
 *
 * Inner features allow sub division inside the parent feature. It provides a more atomic control
 */
export type FeatureSettings = {
  active?: boolean;
  criteria?: CriteriaConditions[];
  features?: FeatureToggles;
};

export type StaticFeatureInfo = {
  [x: string]: boolean | StaticFeatureInfo;
};
export type FeatureToggles = Record<string, FeatureSettings>;
export type StaticFeatureToggles = Record<Exclude<string, "active">, StaticFeatureInfo>;
