import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enGB, enUS, sv } from "date-fns/locale";

import { useProfileContext } from "contexts/ProfileContext";

export const MUILocalizationProvider: React.FC = ({ children }) => {
  const { profile } = useProfileContext();
  const locales = { enUS, enGB, sv };

  let language: keyof typeof locales;
  switch (profile?.locale) {
    case "en-GB":
      language = "enGB";
      break;
    case "en-US":
      language = "enUS";
      break;
    case "sv-SE":
      language = "sv";
      break;
    default:
      language = "enUS";
  }

  const locale = locales[language];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
