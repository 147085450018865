import { useEffect, useState } from "react";

import { useProfileContext } from "contexts/ProfileContext";
import { DataRegionTypes, MarketTypes } from "types";
import moveItem from "utils/array/moveItem";
import reconcile from "utils/array/reconcile";
import { getAppDataRegion } from "utils/region";
import { getStorageValue, setStorageValue } from "utils/storage";

import type { Patient } from "./queries/fetchPatients";

export const allColumns = [
  "name",
  "preferredName",
  "coverage",
  "subscription",
  "primaryJoint",
  "status",
  "week",
  "id",
  "lastActivityCompletedAt",
  "lastMedicalReferralSignatureAt",
  "referralProcessStatus",
  "activityAdherence",
  "addressState",
] as const;
export type ColumnType = typeof allColumns[number];

type TF = (k: string) => string;

type DataColumn = {
  // return the label for the column
  label: (t: TF) => string;
  defaultEnabled: boolean;
  // used to sort by the column
  getComparable: (p: Patient) => string | number;
  // used to search with the column
  searchTokens: (patient: Patient) => Array<string>;
  // used to calculate how much space is needed when the column is enabled
  relativeWidth: number;
};

const dataColumns: Record<ColumnType, DataColumn> = {
  name: {
    label: t => t(`patients.name`),
    defaultEnabled: true,
    getComparable: p => p.name,
    searchTokens: patient => patient.name.split(" "),
    relativeWidth: 4,
  },
  activityAdherence: {
    label: t => t(`patients.adherence`),
    defaultEnabled: false,
    getComparable: p => p.adherence,
    searchTokens: patient => [`${patient.adherence}`],
    relativeWidth: 2,
  },
  preferredName: {
    label: t => t("patients.header_info.preferred_name"),
    defaultEnabled: false,
    getComparable: p => p.preferredName,
    searchTokens: patient => patient.preferredName.split(" "),
    relativeWidth: 2,
  },
  coverage: {
    label: t => {
      switch (getAppDataRegion()) {
        case DataRegionTypes.US:
          return t("patients.header_info.coverage");
        case DataRegionTypes.UK:
          return t("form.payer_name");
        default:
          return "";
      }
    },
    defaultEnabled: true,
    getComparable: p => p.coverage || "",
    searchTokens: patient => patient.coverage?.split(" ") || [],
    relativeWidth: 2,
  },
  subscription: {
    label: t => {
      switch (getAppDataRegion()) {
        case DataRegionTypes.US:
          return t("patients.header_info.subscription");
        default:
          return "";
      }
    },
    defaultEnabled: true,
    getComparable: p => p.subscription || "",
    searchTokens: patient => patient.subscription?.split(" ") || [],
    relativeWidth: 2,
  },
  primaryJoint: {
    label: t => t("patients.ailment"),
    defaultEnabled: false,
    getComparable: p => (p.primaryJoint ? `${p.primaryJoint.location} ${p.primaryJoint.lateral_location}` : ""),
    searchTokens: p => (p.primaryJoint ? [p.primaryJoint.location, p.primaryJoint.lateral_location] : []),
    relativeWidth: 2,
  },
  status: {
    label: t => t(`patients.status`),
    defaultEnabled: true,
    getComparable: p => p.status,
    searchTokens: patient => [patient.status],
    relativeWidth: 2,
  },
  week: {
    label: t => t(`patients.week`),
    defaultEnabled: true,
    getComparable: p => p.week,
    searchTokens: patient => [patient.week.toString()],
    relativeWidth: 2,
  },
  id: {
    label: t => t(`patients.id`),
    defaultEnabled: true,
    getComparable: p => p.id,
    searchTokens: patient => [patient.id.toString()],
    relativeWidth: 2,
  },
  lastActivityCompletedAt: {
    label: t => t(`patients.last_activity_completed_at`),
    defaultEnabled: false,
    getComparable: p => p.lastActivityCompletedAt ?? "",
    searchTokens: p => (p.lastActivityCompletedAt ? [p.lastActivityCompletedAt] : []),
    relativeWidth: 2,
  },
  lastMedicalReferralSignatureAt: {
    label: t => t(`patients.last_medical_referral_signed_at`),
    defaultEnabled: false,
    getComparable: p => p.lastMedicalReferralSignatureAt ?? "",
    searchTokens: p => (p.lastMedicalReferralSignatureAt ? [p.lastMedicalReferralSignatureAt] : []),
    relativeWidth: 2,
  },
  referralProcessStatus: {
    label: t => t(`patients.referral_process_status.title`),
    defaultEnabled: false,
    getComparable: p => p.referralProcessStatus ?? "",
    searchTokens: p => (p.referralProcessStatus ? [p.referralProcessStatus] : []),
    relativeWidth: 2,
  },
  addressState: {
    label: t => t(`patients.header_info.address_state`),
    defaultEnabled: true,
    getComparable: p => p.addressStateCode ?? "",
    searchTokens: patient => [patient.addressStateCode ?? ""],
    relativeWidth: 3,
  },
};

const availableColumns = (market?: string): Array<ColumnType> => {
  const region = getAppDataRegion();
  return allColumns.filter(column => {
    if (column === "coverage") return region === DataRegionTypes.US || region === DataRegionTypes.UK;
    if (column === "subscription") return region === DataRegionTypes.US;
    if (column === "lastMedicalReferralSignatureAt") return market === MarketTypes.FR;
    if (column === "referralProcessStatus") return market === MarketTypes.FR;
    if (column === "addressState") return region === DataRegionTypes.US;
    return true;
  });
};

export const columnLabel = (t: TF, column: ColumnType): string => dataColumns[column].label(t);

export const compareFn = (selectedColumn: ColumnType, descending: boolean) => {
  return (pa: Patient, pb: Patient): number => {
    const x = dataColumns[selectedColumn].getComparable(pa);
    const y = dataColumns[selectedColumn].getComparable(pb);

    if (descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };
};

export const matchesSearch = (patient: Patient, columns: Array<ColumnType>, searchString: string): boolean => {
  const segments = searchString.trim().toLowerCase().split(" ");
  return segments.every(segment =>
    columns.some(c => {
      const tokens = dataColumns[c].searchTokens(patient).map(t => t?.trim().toLocaleLowerCase());
      return tokens.some(t => t?.includes(segment));
    })
  );
};

export const minTableWidth = (columns: Array<ColumnType>): number => {
  return columns.reduce((acc, c) => {
    return acc + dataColumns[c].relativeWidth * 80;
  }, 0);
};

const getColumnPreferences = () => {
  const stored = getStorageValue("datacolumns.columnPreference");

  if (stored) {
    return stored as Record<ColumnType, boolean>;
  }
  return undefined;
};

const getColumnOrderPreference = () => {
  const stored = getStorageValue("datacolumns.orderPreference");
  if (stored) {
    return stored as Array<ColumnType>;
  }
  return undefined;
};

export type DataColumnsContextType = {
  enabledColumns: Record<ColumnType, boolean>;
  columns: Array<ColumnType>;
  moveColumn: (from: ColumnType, to: ColumnType) => void;
  toggleColumn: (column: ColumnType) => void;
};

export const useDataColumns = (): DataColumnsContextType => {
  const defaults = allColumns.reduce((acc, column) => {
    return { ...acc, [column]: dataColumns[column].defaultEnabled };
  }, {} as Record<ColumnType, boolean>);
  const { profile } = useProfileContext();
  const market = profile?.market;
  const preferences = getColumnPreferences() || ({} as Record<ColumnType, boolean>);

  const [enabledColumns, setEnabledColumns] = useState(
    availableColumns(market).reduce((acc, column) => {
      if (preferences[column] !== undefined) {
        return { ...acc, [column]: preferences[column] };
      }
      return { ...acc, [column]: defaults[column] };
    }, {} as Record<ColumnType, boolean>)
  );

  const columnOrderPreference = getColumnOrderPreference() ?? [];

  // `orderedColumns` contains ALL available columns, in order.
  // Otherwise we would "forget" column as the user enable/disables columns.
  const [orderedColumns, setOrderedColumns] = useState<Array<ColumnType>>(
    reconcile(columnOrderPreference, availableColumns(market))
  );

  // `columns` contains ENABLED available columns, in order.
  const [columns, setColumns] = useState<Array<ColumnType>>(getEnabledOrderedColumns(orderedColumns, enabledColumns));

  useEffect(() => {
    setStorageValue("datacolumns.columnPreference", enabledColumns);
  }, [enabledColumns]);

  useEffect(() => {
    setStorageValue("datacolumns.orderPreference", orderedColumns);
  }, [orderedColumns]);

  useEffect(() => {
    setColumns(getEnabledOrderedColumns(orderedColumns, enabledColumns));
  }, [enabledColumns, orderedColumns]);

  return {
    enabledColumns,
    columns,
    moveColumn: (from: ColumnType, to: ColumnType) => {
      const fromIndex = orderedColumns.indexOf(from);
      const toIndex = orderedColumns.indexOf(to);
      const nextOrder = moveItem(orderedColumns, fromIndex, toIndex);

      setOrderedColumns(nextOrder);
    },
    toggleColumn: (column: ColumnType) => {
      if (columns.length <= 1 && enabledColumns[column]) return;
      setEnabledColumns({ ...enabledColumns, [column]: !enabledColumns[column] });
    },
  };
};

const getEnabledOrderedColumns = (ordered: ColumnType[], enabled: Record<ColumnType, boolean>) =>
  ordered.filter((name: ColumnType) => enabled[name]);
