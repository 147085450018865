import type { DataGridProps } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";

import { useMUIDataGridLocale } from "./useMUIDataGridLocale";

export const MUIDataGrid: React.VFC<DataGridProps> = ({ ...props }) => {
  const locale = useMUIDataGridLocale();

  return (
    <DataGrid
      pageSizeOptions={[5, 10, 25, 50]}
      sx={{ border: 0 }}
      localeText={locale.components.MuiDataGrid.defaultProps.localeText}
      {...props}
    />
  );
};
