import type React from "react";

import { CalendarContextProvider } from "contexts/CalendarContext";
import { DropInCallContextProvider } from "contexts/DropInCallContext";
import DropInCallTimerContextProvider from "contexts/DropInCallContext/DropInCallTimerProvider";
import { FeatureToggleContextProvider } from "contexts/FeatureToggleContext";
import { NotificationsContextProvider } from "contexts/NotificationsContext";
import { PinnedMessagesContextProvider } from "contexts/PinnedMessagesContext";
import { ProfileContextProvider } from "contexts/ProfileContext";
import { SavedInputsContextProvider } from "contexts/SavedInputsContext";
import { VideoCallContextProvider } from "contexts/VideoCallContext";
import { VoiceCallContextProvider } from "contexts/VoiceCallContext";

import CurrentPatientContextProvider from "./providers";
import { MUILocalizationProvider } from "./providers/MUILocalizationProvider";
import PatientListContextProviders from "./providers/PatientListContextProviders";

const ContextProviders: React.FC = ({ children }) => {
  return (
    <ProfileContextProvider>
      <FeatureToggleContextProvider>
        <SavedInputsContextProvider>
          <PatientListContextProviders>
            <CurrentPatientContextProvider>
              <PinnedMessagesContextProvider>
                <VideoCallContextProvider>
                  <VoiceCallContextProvider>
                    <NotificationsContextProvider>
                      <DropInCallContextProvider>
                        <DropInCallTimerContextProvider>
                          <MUILocalizationProvider>
                            <CalendarContextProvider>{children}</CalendarContextProvider>
                          </MUILocalizationProvider>
                        </DropInCallTimerContextProvider>
                      </DropInCallContextProvider>
                    </NotificationsContextProvider>
                  </VoiceCallContextProvider>
                </VideoCallContextProvider>
              </PinnedMessagesContextProvider>
            </CurrentPatientContextProvider>
          </PatientListContextProviders>
        </SavedInputsContextProvider>
      </FeatureToggleContextProvider>
    </ProfileContextProvider>
  );
};

export default ContextProviders;
