import type React from "react";

import useFilters from "routes/patients/PatientsList/PatientsFilter/useFilters";
import { PatientsFilterContext } from "utils/contexts";

const PatientListContextProviders: React.FC = ({ children }) => {
  const filtersState = useFilters();

  return <PatientsFilterContext.Provider value={filtersState}>{children} </PatientsFilterContext.Provider>;
};

export default PatientListContextProviders;
