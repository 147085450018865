import { UserRole } from "api/models/User";
import { MarketTypes } from "types";

import type { FeatureToggles } from "./types";

const TOGGLES: Readonly<FeatureToggles> = {
  EDIT_NOTE_DATE: {
    criteria: [
      {
        market: [MarketTypes.US, MarketTypes.SE, MarketTypes.FR, MarketTypes.GB],
      },
    ],
  },
  WRITE_PATIENT_NOTE: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  SELFCARE: {
    criteria: [
      {
        market: [MarketTypes.SE],
      },
    ],
  },
  END_TREATMENT: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  PATIENT_MESSAGES: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  CALL_PATIENT: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist, UserRole.Doctor],
        },
      },
    ],
  },
  MARK_PATIENT_AS_PRIO: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  PATIENT_STICKY_NOTE: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  DISCHARGE_NOTE_RESOLUTION: {
    criteria: [
      {
        market: [MarketTypes.SE],
      },
    ],
  },
  REFERRALS: {
    criteria: [
      {
        market: [MarketTypes.US],
      },
    ],
  },
  BACK_LIBRARY: {
    criteria: [
      {
        market: [MarketTypes.US, MarketTypes.SE, MarketTypes.FR],
      },
    ],
  },
  R52_ICD_CODE: {
    criteria: [
      {
        market: [MarketTypes.US],
      },
    ],
  },
  USE_ICD_CODES_API: {
    criteria: [
      {
        market: [MarketTypes.US, MarketTypes.SE],
      },
    ],
  },
  PATIENT_COMMENTS: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  PATIENT_SUGGESTED_CARE_EVENT: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  PATIENT_SIGN_NOTES_TABLE: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  PATIENT_PROTOCOL: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  PATIENT_SCHEDULE_CALL_BOX: {
    criteria: [
      {
        profile: {
          roles: [UserRole.Therapist],
        },
      },
    ],
  },
  SHOW_AWAITING_TREATMENT_READY: {
    criteria: [
      {
        market: [MarketTypes.SE, MarketTypes.US],
      },
    ],
  },
};

export default TOGGLES;
