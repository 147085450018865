import type React from "react";
import { useContext } from "react";

import styled, { ThemeContext } from "styled-components";

import { useWindowSize } from "utils/hooks";

interface Props {
  phone: string | undefined;
  country: string;
  className?: string;
}

const PhoneNumber: React.VFC<Props> = ({ phone, country, className }) => {
  const { width } = useWindowSize();
  const theme = useContext(ThemeContext);

  if (!phone) {
    return <></>;
  }

  let formattedPhone = phone;
  switch (country) {
    case "Sweden":
      formattedPhone = `${phone.slice(0, 3)} ${phone.slice(3, 5)} ${phone.slice(5, 8)} ${phone.slice(
        8,
        10
      )} ${phone.slice(10)}`;
      break;
    case "USA":
      formattedPhone = `+${phone.slice(0, 1)} (${phone.slice(1, 4)}) ${phone.slice(4, 7)} ${phone.slice(7)}`;
      break;
    default:
      break;
  }

  return width <= parseInt(theme.breakpointMobile, 10) ? (
    <TextArea className={className} href={`tel:${phone}`}>
      {formattedPhone}
    </TextArea>
  ) : (
    <span className={className}>{formattedPhone}</span>
  );
};

export default PhoneNumber;

const TextArea = styled.a`
  color: ${props => props.theme.colors.redesign.b100};
  font-weight: 500;
  text-decoration: none;
`;
