import type React from "react";
import { useEffect, useState } from "react";

import { format, parseISO, startOfMonth, subMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import type { TooltipProps } from "recharts";
import {
  Bar,
  BarChart,
  CartesianGrid,
  DefaultTooltipContent,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import useGetEarningsUsTherapist from "api/hooks/useGetEarningsUsTherapist";
import { useProfileContext } from "contexts/ProfileContext";
import { BaseButton } from "shared/atoms/BaseButton";
import { convertCentsToDollars } from "utils/convertCentsToDollars";

import { Container, Content, Header, Title } from "./styles";

const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const total = Object.entries(payload[0].payload)
      .filter(([key]) => key !== "start_date")
      .reduce((sum, [, value]) => sum + parseFloat(value as string), 0);

    const newPayload = [
      ...payload,
      {
        name: "Total",
        value: total,
      },
    ];
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  return null;
};

const initialStartDate = format(subMonths(startOfMonth(new Date()), 3), "yyyy-MM-dd");

type EarningsChartData = {
  start_date: string;
  online_assessment?: string;
  re_eval?: string;
  rtm_initial?: string;
  rtm_first?: string;
  rtm_additional?: string;
  rtm_adherence?: string;
};

export const TotalEarnings: React.VFC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { profile } = useProfileContext();
  const [monthCount, setMonthCount] = useState(3);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [chartData, setChartData] = useState<EarningsChartData[] | undefined>(undefined);

  invariant(profile, "Profile should be defined");

  const { data } = useGetEarningsUsTherapist({ therapist_id: profile.id, start_date: startDate });

  useEffect(() => {
    if (data) {
      const earningsArray = Object.entries(data.earnings).map(([start_date, categories]) => {
        const totals = Object.entries(categories).reduce((acc, [category, details]) => {
          acc[category] = convertCentsToDollars(details.total);
          return acc;
        }, {} as Record<string, string>);

        return {
          start_date,
          ...totals,
        };
      });
      setChartData(earningsArray);
    }
  }, [data]);

  useEffect(() => {
    const start = startOfMonth(new Date());
    const subtractedStart = subMonths(start, monthCount);
    const formattedStart = format(subtractedStart, "yyyy-MM-dd");
    setStartDate(formattedStart);
  }, [monthCount]);

  return (
    <Container>
      <Header>{t("earnings_widget.total_earnings.header")}</Header>
      <Content>
        <TopSection>
          <Title>${data?.total && convertCentsToDollars(data.total)}</Title>
          <ButtonsContainer>
            <BaseButton
              text={t("earnings_widget.total_earnings.buttons.last_3_months")}
              onClick={() => setMonthCount(3)}
              variant="secondary"
              small
            />
            <BaseButton
              text={t("earnings_widget.total_earnings.buttons.last_6_months")}
              onClick={() => setMonthCount(6)}
              variant="secondary"
              small
            />
            <BaseButton
              text={t("earnings_widget.total_earnings.buttons.last_12_months")}
              onClick={() => setMonthCount(12)}
              variant="secondary"
              small
            />
          </ButtonsContainer>
        </TopSection>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={348}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="0" vertical={false} stroke="#d7dae1" />
            <XAxis
              dataKey="start_date"
              tickFormatter={value => format(parseISO(value), "MMM yy").toLocaleUpperCase()}
              stroke="#94A3B8"
            />
            <YAxis tickFormatter={value => `$${value}`} axisLine={false} tickLine={false} stroke="#94A3B8" />
            <Tooltip
              formatter={value => `$${value}`}
              labelFormatter={label => format(parseISO(label), "MMMM yy")}
              cursor={(chartData && chartData?.length > 0) ?? false}
              content={props => <CustomTooltip {...props} />}
            />
            <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: 10 }} />
            <Bar
              dataKey="online_assessment"
              name={t("dashboard.tables.notes.types.ONLINE_ASSESSMENT")}
              stackId="a"
              fill={theme.colors.redesign.r100}
            />
            <Bar
              dataKey="re_eval"
              name={t("dashboard.tables.notes.types.RE_EVAL")}
              stackId="a"
              fill={theme.colors.redesign.b100}
            />
            <Bar
              dataKey="rtm_initial"
              name={t("dashboard.tables.notes.types.RTM_INITIAL")}
              stackId="a"
              fill={theme.colors.redesign.g100}
            />
            <Bar
              dataKey="rtm_first"
              name={t("dashboard.tables.notes.types.RTM_FIRST")}
              stackId="a"
              fill={theme.colors.redesign.t100}
            />
            <Bar
              dataKey="rtm_additional"
              name={t("dashboard.tables.notes.types.RTM_ADDITIONAL")}
              stackId="a"
              fill={theme.colors.redesign.o100}
            />
            <Bar
              dataKey="rtm_adherence"
              name={t("dashboard.tables.notes.types.RTM_ADHERENCE")}
              stackId="a"
              fill={theme.colors.redesign.p100}
            />
          </BarChart>
        </ResponsiveContainer>
      </Content>
    </Container>
  );
};

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;

  & > button:not(:last-of-type) {
    margin-right: 8px;
  }
`;
