import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";

import type { GetPatientsArgsSchema, Patients } from "api/schemas/Patients";
import useApiClient from "api/useApiClient";

export const queryKeyPrefix = "getPatients";
type QueryKey = [typeof queryKeyPrefix, number, string[] | undefined];
export const getPatientsQueryKey = (userID: number, enabledColumns?: string[]): QueryKey => [
  queryKeyPrefix,
  userID,
  enabledColumns,
];

const useGetPatients = (
  args: GetPatientsArgsSchema,
  options?: UseQueryOptions<Patients[], Error, Patients[], QueryKey>
) => {
  const client = useApiClient();
  return useQuery(
    getPatientsQueryKey(args.userID, args?.config?.params?.enabled_columns),
    () => client.getPatients(args),
    options
  );
};

export default useGetPatients;
