import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { CalendarIcon, CommentsIcon, MessageIcon, SmallBlueClock, StarYellow } from "assets";
import { PaymentMethodTag } from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/components/PaymentMethodTag";
import ActionLink from "shared/atoms/ActionLink";
import { HybridCareIcon } from "shared/atoms/HybridCareIcon";
import PremiumIcon from "shared/atoms/PremiumIcon";
import Tag from "shared/atoms/Tag";

import { TherapistAssignmentRole } from "../../queries/TherapistAssignmentRole";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
}

export const Icons: React.VFC<Props> = ({ params }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const {
    appointment_to_schedule,
    ce_structure,
    id,
    new_comments,
    new_messages,
    new_patient,
    payment_method,
    premium_type,
    prio,
    therapist_assignment_role,
  } = params.row;

  return (
    <Container>
      {/* TODO: fix types
          eslint-disable-next-line @typescript-eslint/ban-ts-comment
          @ts-ignore */}
      {new_patient && <Tag label={t("common.new.0") as string} />}
      {prio && (
        <ActionLinkContainer title={t("patients.tool_tip.prio")}>
          <StarYellow />
        </ActionLinkContainer>
      )}
      <HybridCareIcon ceStructure={ce_structure} size="small" margin="0 4px 0 0" />
      <PremiumIcon premiumType={premium_type} size="small" margin="0 4px 0 0" />
      {appointment_to_schedule === "kick_off" && (
        <FontAwesomeIcon
          icon={faClock}
          color={theme.colors.redesign.o100}
          style={{ height: "18px", width: "18px", marginRight: "4px" }}
        />
      )}
      {therapist_assignment_role === TherapistAssignmentRole.SubstituteTherapist && <SmallBlueClock />}
      {payment_method && payment_method === "exemption_card" && (
        <div>
          <PaymentMethodTag paymentMethod={payment_method} small style={{ marginLeft: "4px" }} />
        </div>
      )}
      <div style={{ display: "flex", marginLeft: "auto" }}>
        {appointment_to_schedule && (
          <ActionLinkContainer title={t("patients.tool_tip.appointment_to_schedule")}>
            <ActionLink
              to={
                {
                  pathname: `/messages/${id}`,
                  search: `?meeting_purpose=${appointment_to_schedule}`,
                } as { pathname: string; search: string }
              }
              m="5px"
              dataTestId="calls-icon-link"
            >
              <CalendarIcon data-testid="calls-icon" />
            </ActionLink>
          </ActionLinkContainer>
        )}
        {new_comments && (
          <ActionLinkContainer title={t("patients.tool_tip.new_comments")}>
            <ActionLink
              to={{
                pathname: `/patients/${id}/comments`,
                state: { pathname, text: "patients" },
              }}
              m="5px"
              dataTestId="comments-icon-link"
            >
              <CommentsIcon data-testid="comments-icon" />
            </ActionLink>
          </ActionLinkContainer>
        )}
        {new_messages && (
          <ActionLinkContainer title={t("patients.tool_tip.new_messages")}>
            <ActionLink
              to={{
                pathname: `/messages/${id}`,
              }}
              m="5px"
            >
              <MessageIcon data-testid="messages-icon" />
            </ActionLink>
          </ActionLinkContainer>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ActionLinkContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
