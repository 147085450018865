import type React from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import type { DateValidationError } from "@mui/x-date-pickers/models";
import axios from "axios";
import { format } from "date-fns";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { useDeleteBookingInterval } from "api/hooks/useDeleteBookingInterval";
import useGetHcpServices from "api/hooks/useGetHcpServices";
import useGetProfessionals from "api/hooks/useGetProfessionals";
import { usePostBookingInterval } from "api/hooks/usePostBookingInterval";
import { usePostManagerBookingInterval } from "api/hooks/usePostManagerBookingInterval";
import { usePutBookingInterval } from "api/hooks/usePutBookingInterval";
import type {
  Availability,
  BookingIntervalPayload,
  BookingIntervalResponse,
  Recurrence,
} from "api/schemas/BookingInterval";
import { useCalendarContext } from "contexts/CalendarContext";
import type { SelectedDate } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { generateAvailabilityOptions } from "routes/calendar/helpers";
import type { FormStates } from "routes/calendar/helpers/misc";
import { MUIDropdown } from "shared/atoms/inputs";
import useLocalizedDate from "utils/date";
import { reportError } from "utils/errorReporting";
import { userIsAdmin, userIsTherapist } from "utils/profile/profileHelper";

import AppointmentInterval from "../AppointmentInterval";
import type { SetSelectArgs } from "../Calendar/Calendar";
import { convertDateStringToObject, formDateToJSDates, generateOptions } from "../helpers";
import { MultiselectField } from "../MultiselectField";

import { FormFooter } from "./FormFooter";
import { getDefaultDate } from "./getDefaultDate";
import { Container, Form, Input, Wrapper } from "./styles";

export type FormData = {
  availability: Availability;
  date: string;
  end_time: number;
  personnel: number;
  recurrence: Recurrence;
  service_ids: string[];
  start_time: number;
};

interface Props {
  selectedBookingInterval: BookingIntervalResponse | null;
  selectedDate?: SelectedDate | null;
  onClose: (booked?: boolean) => void;
  refetchAppointments?: () => void;
  setSelect?: (args: SetSelectArgs) => void;
}

export const AvailabilityForm: React.VFC<Props> = ({
  selectedBookingInterval,
  selectedDate,
  onClose,
  refetchAppointments,
  setSelect,
}) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { isValid } = useLocalizedDate();
  const { showMyAvailability, setShowMyAvailability } = useCalendarContext();

  const [editMode] = useState(!!selectedBookingInterval);
  const [selectedStartTime, setSelectedStartTime] = useState<number>();
  const [selectedEndTime, setSelectedEndTime] = useState<number>();
  const [formState, setFormState] = useState<FormStates>("default");
  const [error, setError] = useState<string | null>(null);
  const showMyAvailabilityRef = useRef(showMyAvailability);

  invariant(profile);
  const isAdmin = userIsAdmin(profile);
  const isTherapist = userIsTherapist(profile);
  const isOnlyAdmin = isAdmin && !isTherapist;

  const deleteBookingInterval = useDeleteBookingInterval();
  const postBookingInterval = usePostBookingInterval();
  const putBookingInterval = usePutBookingInterval();
  const postManagerBookingInterval = usePostManagerBookingInterval();

  const { data: professionals = [] } = useGetProfessionals(profile.id, { enabled: isAdmin });

  const { data: hcpServices = [] } = useGetHcpServices(profile.id, { enabled: isTherapist });
  const serviceOptions = generateOptions({
    array: hcpServices.filter(service => service.bookable_by_patients),
    labelKey: "name",
    valueKey: "id",
  });
  // Add blocked time to the services if not editing existing
  if (!editMode) {
    serviceOptions.push({
      value: "unavailable",
      label: t("booking.calendar.event_title.blocked_time"),
    });
  }

  const [dateError, setDateError] = useState<DateValidationError | null>(null);

  const errorMessage = useMemo(() => {
    switch (dateError) {
      case "disablePast": {
        return t("errors.past_date");
      }

      case "invalidDate": {
        return t("errors.invalid_date");
      }

      default: {
        return "";
      }
    }
  }, [dateError]);

  const personnelDefaultState = [{ label: `${profile?.first_name} ${profile?.last_name}`, value: profile.id }];
  const [personnelOptions, setPersonnelOptions] = useState(personnelDefaultState);

  const recurrenceOptions = [
    { label: t("booking.form.recurrence_options.none"), value: "none" },
    { label: t("booking.form.recurrence_options.weekly"), value: "weekly" },
  ];

  const form = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      date: getDefaultDate(selectedBookingInterval?.start_time),
      service_ids: [selectedBookingInterval?.health_care_service?.id?.toString() ?? ""],
      personnel: personnelOptions[0]?.value,
      start_time: selectedBookingInterval?.start_time
        ? convertDateStringToObject(selectedBookingInterval.start_time).startTime
        : undefined,
      end_time: selectedBookingInterval?.end_time
        ? convertDateStringToObject(selectedBookingInterval.end_time).startTime
        : undefined,
      recurrence: selectedBookingInterval?.recurrence ?? "none",
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = form;

  const selectedServices = watch("service_ids");
  const isUnavailable = selectedServices.includes("unavailable");

  const watchDate = watch("date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");
  const selectedPersonnel = watch("personnel");

  useEffect(() => {
    if (selectedServices.includes("unavailable")) {
      setValue("service_ids", [selectedServices.at(-1) ?? ""]);
    }
  }, [selectedServices?.at(-1)]);

  useEffect(() => {
    if (watchDate) {
      if (setSelect) {
        const { startDate, endDate } = formDateToJSDates({
          date: watchDate,
          startTime: watchStartTime ?? 0,
          endTime: watchEndTime ?? 0,
        });
        setSelect({ start: startDate, end: endDate });
      }
    }
  }, [watchDate, watchStartTime, watchEndTime]);

  useEffect(() => {
    const dateStart = selectedDate && convertDateStringToObject(selectedDate.startStr);
    const dateEnd = selectedDate && convertDateStringToObject(selectedDate.endStr);

    if (dateStart?.startTime) {
      setSelectedStartTime(dateStart.startTime);
    }
    if (dateEnd?.startTime) {
      setSelectedEndTime(dateEnd.startTime);
    }
    if (dateStart?.date) {
      setValue("date", dateStart.date);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isUnavailable && professionals.length > 0) {
      setPersonnelOptions(
        professionals.map(professional => ({
          label: `${professional?.health_care_professional_full_name}`,
          value: professional.health_care_professional_id,
        }))
      );
    } else {
      setPersonnelOptions(personnelDefaultState);
    }
  }, [isUnavailable]);

  useEffect(() => {
    if (isTherapist && !showMyAvailability) {
      setShowMyAvailability(true);
    }

    return () => {
      setShowMyAvailability(showMyAvailabilityRef.current);
    };
  }, []);

  const onSetupCallback = (successMessage?: FormStates) => {
    return {
      onSuccess: () => {
        if (refetchAppointments) refetchAppointments();
        setFormState(successMessage ?? "success");
        if (onClose) setTimeout(() => onClose(true), 3000);
      },
      onError(e: unknown) {
        if (axios.isAxiosError(e)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(t(translateError(e.response?.data.error_code)));
        }
        setFormState("default");
        if (e instanceof Error || typeof e === "string") {
          reportError("AvailabilityForm.tsx", e);
        }
      },
    };
  };

  const onDelete = () => {
    if (!selectedBookingInterval) {
      return;
    }

    setFormState("saving");
    setError(null);

    deleteBookingInterval.mutateAsync(
      {
        bookingIntervalID: selectedBookingInterval.id,
        hcProfID: profile.id,
      },
      onSetupCallback()
    );
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    try {
      const { startDate, endDate } = formDateToJSDates({
        date: formData.date,
        startTime: formData.start_time,
        endTime: formData.end_time,
        ianaTimeZone: profile.iana_timezone,
      });
      const startDateISOStr = startDate.toISOString();
      const endDateISOStr = endDate.toISOString();

      setFormState("saving");
      setError(null);

      const data: BookingIntervalPayload = {
        availability: isUnavailable ? "unavailable" : "available",
        end_time: endDateISOStr,
        note: "",
        recurrence: formData.recurrence,
        start_time: startDateISOStr,
      };

      if (editMode && selectedBookingInterval) {
        putBookingInterval.mutateAsync(
          {
            bookingIntervalID: selectedBookingInterval.id,
            data: { ...data, service_id: parseInt(formData.service_ids[0], 10) },
            hcProfID: profile.id,
          },
          onSetupCallback()
        );
      } else {
        postBookingInterval.mutateAsync(
          {
            data: { ...data, service_ids: formData.service_ids.map(id => parseInt(id, 10)) },
            hcProfID: profile.id,
          },
          onSetupCallback()
        );
      }
    } catch (e) {
      if (e instanceof Error || typeof e === "string") {
        reportError("AvailabilityForm.tsx", e);
      }
    }
  });

  const onSubmitManager = handleSubmit((formData: FormData) => {
    try {
      const { startDate, endDate } = formDateToJSDates({
        date: formData.date,
        startTime: formData.start_time,
        endTime: formData.end_time,
        ianaTimeZone: profile.iana_timezone,
      });
      const startDateISOStr = startDate.toISOString();
      const endDateISOStr = endDate.toISOString();

      setFormState("saving");
      setError(null);

      const data: BookingIntervalPayload = {
        availability: "unavailable",
        end_time: endDateISOStr,
        note: "",
        recurrence: "none",
        service_ids: formData.service_ids.map(id => parseInt(id, 10)),
        start_time: startDateISOStr,
      };

      postManagerBookingInterval.mutateAsync(
        {
          data,
          managerID: profile.id,
          hcProfID: formData.personnel,
        },
        onSetupCallback()
      );
    } catch (e) {
      if (e instanceof Error || typeof e === "string") {
        reportError("AvailabilityForm.tsx", e);
      }
    }
  });

  return (
    <Container>
      <FormProvider {...form}>
        <Form onSubmit={selectedPersonnel === profile.id ? onSubmit : onSubmitManager} data-testid="availability-form">
          <Input>
            <MultiselectField
              name="service_ids"
              label={t("booking.form.service")}
              optionsFull={serviceOptions}
              singleSelect={editMode}
              required
              error={errors.service_ids && t("errors.field.required")}
            />
          </Input>

          <Input className="date-and-time">
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Wrapper>
                  <DatePicker
                    name="date"
                    format="yyyy-MM-dd"
                    value={value ? new Date(`${value}T00:00:00`) : null}
                    onChange={input => {
                      if (input && isValid(input)) {
                        onChange(format(input, "yyyy-MM-dd"));
                      } else {
                        onChange(input);
                      }
                    }}
                    onError={newError => setDateError(newError)}
                    slotProps={{
                      textField: {
                        helperText: errorMessage,
                      },
                    }}
                    disablePast
                  />
                </Wrapper>
              )}
            />
          </Input>

          <Input>
            <AppointmentInterval
              options={generateAvailabilityOptions(undefined, 5)}
              setValue={setValue}
              getValues={getValues}
              selectedStartTime={selectedStartTime}
              selectedEndTime={selectedEndTime}
              errors={errors}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.personnel")}
              name="personnel"
              options={personnelOptions ?? []}
              disabled={!isAdmin}
              required
              error={errors.personnel && t("errors.field.required")}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.recurrence")}
              name="recurrence"
              options={recurrenceOptions ?? []}
              disabled={isOnlyAdmin}
              required
              error={errors.recurrence && t("errors.field.required")}
            />
          </Input>

          <FormFooter
            formState={formState}
            editMode={editMode}
            error={error}
            event={selectedBookingInterval}
            deleteAppointment={onDelete}
            text={{
              delete: t("booking.buttons.delete"),
              confirmDeletion: t("booking.form.confirm_deletion_booking_interval"),
              success: t("booking.success_booking_interval"),
              successDeletion: t("booking.success_deletion_booking_interval"),
            }}
          />
        </Form>
      </FormProvider>
    </Container>
  );
};
